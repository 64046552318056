import createCallerInfoBoxElement, {
  createCallerInfoBoxHtml,
} from './createCallerInfoBoxElement';

const aternityLinksLambdaUrl =
  'https://4z4x82i6a0.execute-api.ap-southeast-2.amazonaws.com/prod?contactId=';

// wait 12 seconds and try to fetch links
function getAternityLinks(contactId, callerLinksBoxElement) {
  setTimeout(async () => {
    const url = aternityLinksLambdaUrl + contactId;
    const response = await fetch(url);
    const responseJson = await response.json();
    if (responseJson.links && callerLinksBoxElement) {
      const newLinksHtml = createCallerInfoBoxHtml(responseJson.links);
      callerLinksBoxElement.append(newLinksHtml);
    }
  }, 12 * 1000);
}

function initialiseContactListeners(client) {
  const linksContainer = document.querySelector('.links-container');

  let connectContact;
  connect.contact(function(contact) {
    connectContact = contact;
    let callerLinksBoxElement;

    connectContact.onConnecting(function(c) {
      console.log('onConnecting');
      const attributes = connectContact.getAttributes();
      if (attributes.callerLinks.value) {
        try {
          const callerLinks = JSON.parse(attributes.callerLinks.value);
          console.log('callerLinks', callerLinks);
          callerLinksBoxElement = createCallerInfoBoxElement(callerLinks);
          linksContainer.prepend(callerLinksBoxElement);
          // only if there's a cherwell link in callerLinks, then get aternity links (as it depends on cherwell)
          if (callerLinks.length) {
            getAternityLinks(connectContact.contactId, callerLinksBoxElement); // start getting aternity links
          }
          // refreshHeight(container);
          // openNewTabs(callerLinks);
        } catch (error) {
          console.log();
        }
      }

      const contactConnection = connectContact.getConnections()[1];
      const contactStatus = connectContact.getStatus();
      console.log('attributes', attributes);
      console.log('contactConnection', contactConnection);
      console.log('contactStatus', contactStatus);
    });

    connectContact.onRefresh(params => {
      // console.log('onRefresh', params);
    });

    connectContact.onConnected(params => {
      console.log('onConnected', params);
    });

    connectContact.onEnded(params => {
      console.log('onEnded', params);
      if (callerLinksBoxElement) {
        callerLinksBoxElement.remove();
        // refreshHeight(container);
      }
    });
  });
}

export default initialiseContactListeners;
