import openWindow from './openWindow';

function initialiseLogin(client) {
  // if there's a custom login url specified then use that
  const loginURL = client.CustomLoginUrl
    ? `https://${client.CustomLoginUrl}`
    : `https://${client.ConnectAlias}.my.connect.aws/login`;
  let loginWindow;

  connect.core
    .getEventBus()
    .subscribe(connect.EventType.ACK_TIMEOUT, function() {
      try {
        connect
          .getLog()
          .warn('ACK_TIMEOUT occurred, attempting to pop the login page.');
        loginWindow = openWindow(loginURL);
      } catch (e) {
        connect
          .getLog()
          .error(
            'ACK_TIMEOUT occurred but we are unable to open the login popup.' +
              e,
          )
          .withException(e);
      }

      connect.core
        .getEventBus()
        .subscribe(connect.EventType.ACKNOWLEDGE, function() {
          loginWindow.close();
        });
    });
}

export default initialiseLogin;
