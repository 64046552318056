export function createCallerInfoBoxHtml(callerLinks) {
  if (callerLinks.length) {
    return callerLinks
      .map(link => {
        return `<div class="caller-link">${link.labelText} - <a href="${link.linkUrl}" target="_blank">${link.linkText}</a></div>`;
      })
      .join('\n');
  }
  return '';
}

export default function createCallerInfoBoxElement(info) {
  const element = document.createElement('div');
  element.innerHTML = createCallerInfoBoxHtml(info);
  return element;
}
