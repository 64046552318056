import pipe from 'ramda/src/pipe';
import replace from 'ramda/src/replace';
import split from 'ramda/src/split';
import reduce from 'ramda/src/reduce';

const convertSearchParamsToObject = pipe(
  replace('?', ''),
  split('&'),
  reduce((accumulator, paramString) => {
    const [paramName, paramValue] = paramString.split('=');
    return { ...accumulator, [paramName]: paramValue || '' };
  }, {}),
);

export default convertSearchParamsToObject;
