function initialiseIframe(client) {
  const ccpURL = `https://${client.ConnectAlias}.my.connect.aws/ccp`;
  const iframeCcpContainer = document.querySelector('.iframe-container');
  connect.core.initCCP(iframeCcpContainer, {
    ccpUrl: ccpURL,
    loginPopup: false, // Optional, defaults to true. Set to false to disable the login popup which is shown when the user's authentication expires.
    softphone: {
      allowFramedSoftphone: true, // Normally, the softphone microphone and speaker components are not allowed to be hosted in an iframe. This is because the softphone must be hosted in a single window or tab. The window hosting the softphone session must not be closed during the course of a softphone call or the call will be disconnected. If allowFramedSoftphone is true, the softphone components will be allowed to be hosted in this window or tab.
      disableRingtone: false,
    },
  });
}

export default initialiseIframe;
