import openWindow from './openWindow';

function goOfflineLogout(client) {
  const logoutURL = `https://${client.ConnectAlias}.my.connect.aws/logout`;

  const offlineState = window.ccp.agent
    .getAgentStates()
    .filter(function(state) {
      console.log('this is state: ' + state);
      return state.type === connect.AgentStateType.OFFLINE;
    })[0];

  window.ccp.agent.setState(offlineState, {
    success: function() {
      console.log('Set agent status to Offline via Streams');
      const logoutWindow = openWindow(logoutURL);
      logoutWindow.close();
      // clear iframe container
      document.querySelector('.iframe-container').innerHTML = '';
    },
    failure: function() {
      console.log('Failed to set agent status to Offline via Streams');
    },
  });
}

function initialiseLogout(client) {
  document
    .querySelector('.logout-button')
    .addEventListener('click', () => goOfflineLogout(client));
}

export default initialiseLogout;
