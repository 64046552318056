function initialiseStateChangeListener() {
  // Reset form after agent becomes available again
  connect.agent(agent => {
    window.ccp.agent = agent;
    console.log('agent', agent);
    agent.onStateChange(event => {
      console.log('onStateChange', event);
      if (event.newState === 'Available') {
        // document.getElementById("contactDetails").reset();
        // $('#attributes').DataTable().clear().draw();
        // document.getElementById('updateNameIMG').src = "../assets/sync.svg";
      }
    });
  });
}

export default initialiseStateChangeListener;
