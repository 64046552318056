import './utils/connectCCP';
import initialiseIframe from './utils/initialiseIframe';
import initialiseLogin from './utils/initialiseLogin';
import initialiseLogout from './utils/initialiseLogout';
import initialiseContactListeners from './utils/initialiseContactListeners';
import initialiseStateChangeListener from './utils/initialiseStateChangeListener';

import './reset.scss';
import './main.scss';
import convertSearchParamsToObject from './utils/convertSearchParamsToObject';

function initialiseCCP(client) {
  window.ccp = window.ccp || {};

  initialiseIframe(client);
  initialiseLogin(client);
  initialiseLogout(client);
  initialiseContactListeners(client);
  initialiseStateChangeListener(client);
}

(async function init() {
  const params = convertSearchParamsToObject(location.search);
  if (!params['connect-alias']) {
    const error = 'Please specify "connect-alias" parameter in the url';
    alert(error);
    throw new Error(error);
  }
  const client = {
    ConnectAlias: params['connect-alias'],
    CustomLoginUrl: params['custom-login-url'],
  };

  initialiseCCP(client);
})();
