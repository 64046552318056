function openWindow(url) {
  const width = 500;
  const height = 600;
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;

  const newWindow = window.open(
    url,
    true,
    'width=' +
      width +
      ',height=' +
      height +
      ',menubar=no,status=no,toolbar=no,left=' +
      left +
      ',top=' +
      top,
  );

  return newWindow;
}

export default openWindow;
